@import url(http://fonts.googleapis.com/css?family=Open+Sans);

html {
  scroll-padding-top: 88px;
}

body {
  font-family: 'Open Sans', sans-serif;
}

.App {
  text-align: center;
}

.nav-link {
  padding-left: 2rem;
  padding-right: 2rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.site-footer {
  background-color: #e4e6eb;
  padding: 1rem;
}

.footer-link {
  text-decoration: none;
  padding: 1.5rem;
  font-size: x-large;
}

.banner {
  height: 500px;
  background-image: url(/public/headshot-dark.jpg);
  background-color: #12120f;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 35%;
}

.button-container {
  margin-bottom: 5rem;
}

.inside-banner {
  color: white;
  padding-top: 35px
}

.blue-line {
  height: 1px;
  width: 70px;
  background: blue;
  margin: 1.5rem auto;
}

.spacing {
  height: 2rem;
}

.skills-icon {
  color: #0d6efd;
  margin-bottom: 1.5rem;
}

.large-spacing {
  height: 5rem;
}

.text-align-left {
  text-align: left;
}

.vertical-align-middle {
  vertical-align: middle;
}

.tutors-section {
  text-align: center;
  height: 50px;
}

.tutors-logo {
  vertical-align: middle;
  height: 50px;
}

.carousel {
  margin: 2rem;
  padding: 0 2rem;
}

.carousel-item {
  font-style: italic;
  padding: 0 4rem;
  font-size: 0.75em;
  overflow-wrap: normal;
}

@media (min-width: 576px) {
  .carousel-item {
    font-size: 0.875em;
  }
}

@media (min-width: 768px) {
  .carousel-item {
    font-size: 0.975em;
  }
}

@media (min-width: 992px) {
  .carousel-item {
    font-size: 1.05em;
    padding: 0 6rem;
  }
}

@media (min-width: 1200px) {
  .carousel-item {
    font-size: 1.2em;
    padding: 0 8rem;
  }
}

@media (min-width: 1400px) {
  .carousel-item {
    font-size: 1.3em;
    padding: 0 10rem;
  }
}